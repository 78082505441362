<ion-content scroll-y="false">
  <!-- Set to either qr-scan--cam-right or qr-scan--cam-left -->
  <div class="page-wrapper history">
    <shared-close (tap)="close()"></shared-close>
    <div class="history--header">
      <h1 class="history--title">{{ translate._("CUSTOMER_HISTORY") }}</h1>
      <div class="history--header-columns">
        <div class="history--header-column">
          <p class="history--header-column--num">
            {{ totalRedeem * -1 | number: "":translate.getSessionLanguage() }}
            <span class="history--header-column--title">{{ translate._("POINTS_EXCHANGED") }}</span>
          </p>
        </div>
        <div class="history--header-column">
          <p class="history--header-column--num">
            {{ firstAction?.unixTimeStamp ? (firstAction.unixTimeStamp | date: "dd.MM.yy") : "-" }}
            <span class="history--header-column--title">{{ translate._("PARTNER_SINCE") }}</span>
          </p>
        </div>
        <div class="history--header-column">
          <p class="history--header-column--num">
            {{ totalEarn | number: "":translate.getSessionLanguage() }}
            <span class="history--header-column--title">{{ translate._("POINTS_EARNED") }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="history--list-wrapper">
      <div class="history--list-header">
        <div class="history--column history--column__first history--list-title">{{ translate._("ACTIVITIES") }}</div>
        <div class="history--column history--column__second history--list-title"></div>
        <div class="history--column history--column__third history--list-title">{{ translate._("POINTS") }}</div>
      </div>
      <ion-list lines="none" class="history--list">
        <ion-item
          no-padding
          class="history--list-item history--list-item__waiting"
          *ngFor="let transaction of transactions | orderBy: 'transactionDateTime unixTimeStamp'"
        >
          <div class="history--column history--column__first history--list-title">
            <span [innerHTML]="transaction?.description"></span>
            <span>
              {{ transaction?.transactionDateTime?.unixTimeStamp | date: "dd.MM.yy HH:mm:ss" }}
            </span>
          </div>
          <div class="history--column history--column__second history--list-title"></div>
          <div class="history--column history--column__third">
            <p class="history--column history--column__third__text">
              {{ transaction?.points | number: "":translate.getSessionLanguage() }}
            </p>
            <i-arrow-down-circle
              class="history--column history--column__third__icon-negative"
              *ngIf="transaction?.points < 0"
            ></i-arrow-down-circle>
            <i-arrow-up-circle
              class="history--column history--column__third__icon-positive"
              *ngIf="transaction?.points >= 0"
            ></i-arrow-up-circle>
          </div>
        </ion-item>
        <ion-spinner *ngIf="loading" class="history--list__spinner" color="primary"></ion-spinner>
      </ion-list>
    </div>
  </div>
</ion-content>
