import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Plugins } from "@capacitor/core";
import { ModalController } from "@ionic/angular";
import { COUNTRIES } from "shared/lib/common/constants";
import { IRegister, IRegistrationCode } from "shared/lib/common/interfaces";
import { AdminService, ToastService, TranslateService, UserService } from "shared/lib/common/services";
import { ConfigService } from "shared/lib/common/services";

export interface IRegisterModalPageInputs {
  registrationType: IRegistrationCode;
  keyCode: string;
  userReferenceCode: string;
}

export type DismissState = "SUCCESS" | "CANCELLED" | "NOT_SUCCESS";

export interface IRegisterModalPageOutputs {
  data?: DismissState;
}

@Component({
  selector: "shared-register",
  templateUrl: "./register.page.html",
  styleUrls: ["./register.page.scss"],
})
export class RegisterPage implements OnInit {
  public form: FormGroup;
  public isLoading: boolean;
  public showMoreText: boolean;
  public client: string;
  public countries: Array<{ name: string; code: string }> = [];

  @Input() public registrationType: IRegistrationCode;
  @Input() public keyCode: string;
  @Input() public userReferenceCode: string;

  constructor(
    public t: TranslateService,
    private modal: ModalController,
    private fb: FormBuilder,
    private admin: AdminService,
    private toast: ToastService,
    private config: ConfigService,
    private user: UserService,
  ) {}

  public ngOnInit(): void {
    this.isLoading = false;
    this.countries = COUNTRIES;
    this.client = this.config.getMountingConfig().legalName;

    const SimForm = {
      emailAddress: ["", [Validators.required, Validators.email]],
      declarationOfConsent: [false],
      acceptConsent: [false],
    };

    const ExtForm = {
      ...SimForm,
      givenName: ["", Validators.required],
      familyName: ["", Validators.required],
      streetHouseNo: ["", Validators.required],
      zipCode: ["", Validators.required],
      city: ["", Validators.required],
      countryCode: ["", Validators.required],
      genderCode: ["", Validators.required],
      birthdate: ["", Validators.required],
      phoneNumber: [""],
    };

    const SimExtForm = {
      ...SimForm,
      givenName: [""],
      familyName: [""],
      streetHouseNo: [""],
      zipCode: [""],
      city: [""],
      countryCode: [""],
      genderCode: [""],
      birthdate: [""],
      phoneNumber: [""],
    };

    switch (this.registrationType) {
      case "SIMEXT":
        this.form = this.fb.group(SimExtForm);
        break;
      case "EXT":
        this.form = this.fb.group(ExtForm);
        break;
      default:
        this.form = this.fb.group(SimForm);
    }
  }

  public async register(): Promise<void> {
    this.isLoading = true;
    if (this.form.valid) {
      const data: IRegister = {
        type: this.registrationType,
        simple: {
          ...this.form.value,
          clientOptins: [
            {
              clientId: this.config.getMountingConfig().clientId,
              emailOptin: this.form.get("declarationOfConsent").value,
              dataOptin: this.form.get("acceptConsent").value,
            },
          ],
          keyCode: this.keyCode,
          language: this.t.getSessionLanguage(),
          channelCode: "A",
          clientId: this.config.getMountingConfig().clientId,
        },
        extended: {
          ...this.form.value,
          userReferenceCode: this.userReferenceCode,
          deviceKey: this.config.getMountingConfig().deviceKey,
        },
      };
      const resp = await this.admin.register(data);

      if (resp.ok === true) {
        this.form.reset();
        const details = await this.user.getDetails(this.keyCode);
        if (details.ok === true) this.user.setUser(details.response);
        else this.toast.show(this.t._(details.error.message), "error");
        this.dismiss("SUCCESS");
      } else {
        if (resp.error.message === "REGISTRATION_USER_EXISTS_NOT_ACTIVATED") {
          this.form.reset();
          this.dismiss("SUCCESS");
        }
        this.toast.show(resp.error.message, "error");
      }
    }
    this.isLoading = false;
  }

  public dismiss(state: DismissState): void {
    this.modal.dismiss(state);
  }

  public async open(url: string): Promise<void> {
    const { Browser } = Plugins;
    await Browser.open({ url });
  }

  public buildName(literal: string, isAlwaysRequired: boolean = false): string {
    return `${this.t._(literal)}${this.registrationType === "EXT" || isAlwaysRequired ? " *" : ""}`;
  }

  public isEnable(): boolean {
    return this.registrationType === "EXT" || this.registrationType === "SIMEXT";
  }
}
