import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Plugins } from "@capacitor/core";
import { AlertController, ModalController } from "@ionic/angular";
import { OverlayEventDetail } from "@ionic/core";
import { SharedRoutes } from "shared/lib/common/enums";
import {
  AdminService,
  P4mService,
  PosConnectionService,
  PrintService,
  ToastService,
  TranslateService,
  UtilsService,
  ConfigService,
  RoutingService,
  EnvironmentService,
} from "shared/lib/common/services";
import { EnsureUnmountLoginModalOutputs, EnsureUnmountLoginModal } from "shared/lib/common/pages";

@Component({
  selector: "shared-v1-configurations",
  templateUrl: "./configurations.page.html",
  styleUrls: ["./configurations.page.scss"],
})
export class ConfigurationsPage implements OnInit {
  public form: FormGroup;
  public loading: boolean;
  public ip: string;
  public unmmountLoading: boolean;

  constructor(
    private environment: EnvironmentService,
    private modal: ModalController,
    private utils: UtilsService,
    private p4m: P4mService,
    private fb: FormBuilder,
    private toast: ToastService,
    private routing: RoutingService,
    public translate: TranslateService,
    public print: PrintService,
    public admin: AdminService,
    public posConnectionService: PosConnectionService,
    public alert: AlertController,
    public config: ConfigService,
  ) {}

  public ngOnInit(): void {
    this.loading = false;
    this.form = this.fb.group({
      ip: [
        this.print.getIp(),
        [
          Validators.required,
          Validators.pattern(/\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b/),
        ],
      ],
    });
  }

  public back(): void {
    this.routing.goForward([SharedRoutes.screenSaver]);
  }

  public async testConnection(ip: string): Promise<void> {
    this.loading = true;
    const response = await this.print.testConnection(ip);
    if (response.ok === true) {
      this.print.setIp(ip);
      this.toast.show(this.translate._("PRINTER_CONNECTED"), "info");
    } else {
      this.toast.show(this.translate._(response.error.message as any), "error");
    }
    this.loading = false;
  }

  public removeIp(): void {
    this.form.reset();
    this.print.removeIp();
  }

  public async logOut(): Promise<void> {
    this.unmmountLoading = true;
    const { data } = await this.presentLogin();

    if (data) {
      const resp = await this.admin.unMountDevice();
      if (resp.ok === false) this.toast.show(this.translate._(resp.error.message as any), "error");

      this.admin.logout();
      this.posConnectionService.closeSocketConnection();
      this.routing.resetConfig();

      this.p4m.setUser(data.email);
      this.p4m.setPassword(data.password);

      window.location.href = `/${SharedRoutes.login}`;
    }

    this.unmmountLoading = false;
  }

  public async openBackOffice(): Promise<void> {
    const { Browser } = Plugins;
    await Browser.open({ url: this.environment.getVariable("SELFSERVICE") });
  }

  protected async presentLogin(): Promise<OverlayEventDetail<EnsureUnmountLoginModalOutputs>> {
    const modal = await this.modal.create({ component: EnsureUnmountLoginModal });
    await modal.present();
    return await modal.onDidDismiss();
  }
}
