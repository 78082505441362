import { Injectable } from "@angular/core";
import { LOCAL_STORAGE } from "shared/lib/common/enums";
import {
  ActionType,
  IActionDefinition,
  IArtsResponse,
  IClientComplet,
  IDeviceManagementAuthenticationResponse,
  IDisplayAd,
  IDownloadDisplayAdsResponse,
  IGetAllClientsResponse,
  IGetPermissionUserResponse,
  IGetStoresOfClientResponse,
  IMount,
  IMountDeviceResponse,
  IPartner,
  IPromotion,
  IPromotions,
  IRegister,
  IStore,
  IStoreAPI2,
  IUnMountDeviceResponse,
  IRegistrationCode,
  IExtendedRevenuePointsManuallyResponse,
  IIdentifierType,
} from "shared/lib/common/interfaces";
import { ConfigService } from "shared/lib/common/services/config/config.service";
import { P4mService } from "shared/lib/common/services/p4m/p4m.service";
import { SentryService } from "shared/lib/common/services/sentry/sentry.service";
import { UtilsService } from "shared/lib/common/services/utils/utils.service";
import { IResponse } from "shared/lib/common/interfaces";
import { TranslateService } from "../translate/translate.service";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(
    public translate: TranslateService,
    public p4m: P4mService,
    public utils: UtilsService,
    public configService: ConfigService,
    public sentry: SentryService,
  ) {}

  public async getRegistrationType(): Promise<IResponse<IRegistrationCode>> {
    let registrationType: IRegistrationCode = "NO";
    const resp = await this.getAllClients();
    if (resp.ok === true) {
      const client = resp.response.find(c => String(c.clientId) === this.configService.getMountingConfig().clientId);
      if (client && client.alwaysShowRegistration) {
        switch (this.configService.getMountingConfig().posMode) {
          case "STD":
            registrationType = client.showRegistrationCode;
            break;
          case "SER":
            registrationType = client.registrationModePosRegistrationTypeCode;
            break;
        }
      }
      return { ok: true, response: registrationType };
    }
    return resp;
  }

  public async extendedRevenuePointsManually(
    keyCode: string,
    amount: number,
    extendedRevenueFactsId: number,
    idType: IIdentifierType,
    externalId: string,
  ): Promise<IResponse<IExtendedRevenuePointsManuallyResponse>> {
    return await this.p4m.extendedRevenuePointsManually({
      organization: this.configService.getOrganization(),
      language: this.translate.getSessionLanguage(),
      deviceKey: this.configService.getMountingConfig().deviceKey,
      keyCode,
      extendedRevenueFactsId,
    });
  }

  public async arts(date: string, amount: number, keyCode: string, idType: IIdentifierType): Promise<IResponse<IArtsResponse>> {
    const { workstationId, store } = this.configService.getMountingConfig();
    const resp = await this.p4m.arts({ date, amount, apiKey: this.getApiKey(), workstationId, vatId: store && store.vatId, keyCode });
    const response = this.parseResponse<IArtsResponse>(resp);
    if (response.ok === true && response.response.successInfo && response.response.successInfo.successCode === "BON_ID_ALREADY_EXISTS") {
      return { ok: false, error: { message: "BON_ID_ALREADY_EXISTS" } };
    }
    return response;
  }

  public async readProgramPartners(): Promise<IResponse<Array<IPartner>>> {
    return await this.p4m.readProgramPartners();
  }

  public async readPartnerStores(partnerId: string): Promise<IResponse<Array<IStoreAPI2>>> {
    return await this.p4m.readPartnerStores(partnerId);
  }

  public async getAllClients(): Promise<IResponse<IClientComplet[]>> {
    try {
      const refresh = await this.p4m.deviceManagementLogin({
        userName: this.p4m.getUser(),
        organization: this.configService.getOrganization(),
        password: this.p4m.getPassword(),
      });
      if (refresh.ok === true) {
        const response = this.parseDeviceManagementLogin(refresh.response);
        if (response.ok === true) {
          const resp = this.parseResponse<IGetAllClientsResponse>(
            await this.p4m.getAllClients({
              apiKey: this.getMountingApiKey(),
              organization: this.configService.getOrganization(),
            }),
          );
          if (resp.ok === true) {
            return { ok: true, response: resp.response.clients };
          } else {
            return resp;
          }
        } else {
          return response;
        }
      } else {
        return refresh;
      }
    } catch (error) {
      this.sentry.handleError({ err: error, method: "admin.getAllClients" });
      return { ok: false, error };
    }
  }

  public getClientStore(): IResponse<IStore> {
    try {
      return { ok: true, response: JSON.parse(this.getStore()) as IStore };
    } catch (error) {
      this.sentry.handleError({
        err: error,
        method: "admin.getClientStore",
      });
      return { ok: false, error };
    }
  }

  public async fetchStoresOfClient(clientId: string): Promise<IResponse<IStore[]>> {
    try {
      const refresh = await this.p4m.loginToGetApiKey({
        organization: this.configService.getOrganization(),
        userName: this.p4m.getUser(),
        password: this.p4m.getPassword(),
      });
      if (refresh.ok === true) {
        const refreshParsed = this.parseResponse(refresh);
        if (refreshParsed.ok === true) {
          const resp = this.parseResponse<IGetStoresOfClientResponse>(
            await this.p4m.getStoresOfClient({
              organization: this.configService.getOrganization(),
              apiKey: this.getMountingApiKey(),
              clientId,
            }),
          );
          if (resp.ok === true) {
            if (this.configService.getMountingConfig().storeName) {
              this.setStore(resp.response.stores.find(s => s.storeName === this.configService.getMountingConfig().storeName));
            }
            return { ok: true, response: resp.response.stores };
          } else {
            return resp;
          }
        } else {
          return refreshParsed;
        }
      } else {
        return refresh;
      }
    } catch (error) {
      this.sentry.handleError({
        err: error,
        method: "admin.fetchStoresOfClient",
      });
      return { ok: false, error };
    }
  }

  public async getDisplayAds(deviceKey: string): Promise<IResponse<IDisplayAd[]>> {
    try {
      const resp = await this.p4m.downloadDisplayAds({
        organization: this.configService.getOrganization(),
        apiKey: this.getMountingApiKey(),
        deviceKey,
      });
      if (resp.ok === true) {
        return { ok: true, response: this.parseDisplayAds(resp.response) };
      } else {
        return resp;
      }
    } catch (error) {
      this.sentry.handleError({ err: error, method: "admin.getDisplayAds" });
      return { ok: false, error };
    }
  }

  public async mountDevice(params: IMount): Promise<IResponse<IMountDeviceResponse>> {
    try {
      const apiKey = await this.getApiKeyProcess(this.configService.getOrganization(), params.clientId);
      if (apiKey.ok === true) {
        this.setApiKey(apiKey.response);
      } else {
        return apiKey;
      }
      return this.parseResponse(
        await this.p4m.mountDevice({
          organization: this.configService.getOrganization(),
          apiKey: this.getMountingApiKey(),
          clientId: params.clientId,
          authenticationKey: params.authenticationKey,
          cameraPositionCode: params.cameraPositionCode,
          deviceInformation: params.deviceInformation,
          deviceModel: params.deviceModel,
          language: params.language,
          osCode: params.osCode,
          posModeCode: params.posModeCode,
          storeId: params.storeId,
          workstationId: params.workstationId,
        }),
      );
    } catch (error) {
      this.sentry.handleError({ err: error, method: "admin.mountDevice" });
      return { ok: false, error };
    }
  }

  public async unMountDevice(): Promise<IResponse<IUnMountDeviceResponse>> {
    try {
      const refresh = await this.p4m.loginToGetApiKey({
        userName: this.p4m.getUser(),
        password: this.p4m.getPassword(),
        organization: this.configService.getOrganization(),
      });
      if (refresh.ok === true) {
        const response = this.parseResponse(refresh);
        if (response.ok === true) {
          return this.parseResponse(
            await this.p4m.unMountDevice({
              organization: this.configService.getOrganization(),
              apiKey: this.getMountingApiKey(),
              deviceId: this.configService.getMountingConfig().deviceId.toFixed(),
              fromDevice: true,
            }),
          );
        } else {
          return response;
        }
      } else {
        return refresh;
      }
    } catch (error) {
      this.sentry.handleError({ err: error, method: "admin.unMountDevice" });
      return { ok: false, error };
    }
  }

  public isValidPin(pin: string): boolean {
    const pinCode = this.getPinCode();
    return pinCode && pinCode === pin;
  }

  public async adminLogin(
    email: string,
    organization: string,
    password: string,
  ): Promise<IResponse<{ mountingApiKey: string; token: string }>> {
    try {
      const loginResponse = await this.AdminMultiLogin(email, organization, password);
      if (loginResponse.ok === true) {
        this.p4m.setUser(email);
        this.p4m.setPassword(password);
        this.p4m.setToken(loginResponse.response.token);
        this.setMountingApiKey(loginResponse.response.mountingApiKey);
        return {
          ok: true,
          response: {
            mountingApiKey: loginResponse.response.mountingApiKey,
            token: loginResponse.response.token,
          },
        };
      } else {
        return loginResponse;
      }
    } catch (error) {
      this.sentry.handleError({ err: error, method: "admin.adminLogin" });
      return { ok: false, error: { message: error.message || error } };
    }
  }

  public async reLogin(): Promise<IResponse<string>> {
    try {
      return await this.p4m.login({
        email: this.p4m.getUser(),
        organization: this.configService.getOrganization(),
        password: this.p4m.getPassword(),
      });
    } catch (error) {
      this.sentry.handleError({ err: error, method: "admin.reLogin" });
      return { ok: false, error: { message: error.message || error } };
    }
  }

  public async getPromotions(): Promise<IPromotions> {
    const res = await this.p4m.getActionDefinitions({
      organization: this.configService.getOrganization(),
      deviceKey: this.configService.getMountingConfig().deviceKey,
    });
    let actionDefinitions: IActionDefinition[] = [];
    if (res.ok) {
      actionDefinitions = res.response.actionDefinitions;
    }
    const keyValue: { [key: string]: IPromotion } = {};
    (actionDefinitions || []).forEach(a => {
      keyValue[a.actionType] = {
        factor: a.factor,
        name: a.campaignName,
        points: 0,
        revenue: 0,
        type: a.actionType,
      };
    });
    return {
      rmc1: keyValue[ActionType.RMC1],
      rmc2: keyValue[ActionType.RMC2],
      rmc3: keyValue[ActionType.RMC3],
      rmt: keyValue[ActionType.RMT],
      rmb: keyValue[ActionType.RMB],
    };
  }

  public logout(): void {
    localStorage.clear();
    document.cookie.split(";").forEach(c => {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }

  public isAuthenticated(): boolean {
    if (this.p4m.getUser() && this.p4m.getPassword()) {
      return true;
    } else {
      return false;
    }
  }

  public getPinCode(): string {
    return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.PIN_CODE));
  }

  public getMountingApiKey(): string {
    return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.MOUNTING_APIKEY));
  }

  public getApiKey(): string {
    return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.APIKEY));
  }

  public validateSaveNewPinParams(pin: string): IResponse<{ pin: string }> {
    if (!pin) return { ok: false, error: { message: "EMPTY_PIN" } };
    if (pin.length <= 3) {
      return { ok: false, error: { message: "SHORT_PIN" } };
    }
    return { ok: true, response: { pin } };
  }

  public parseGetPermissionUser(response: IGetPermissionUserResponse): IResponse<string> {
    if (!response.errorInfo) {
      return { ok: true, response: response.user.apiKey };
    } else {
      return { ok: false, error: { message: response.errorInfo.errorCode } };
    }
  }

  public parseResponse<R>(response: IResponse<any>): IResponse<R> {
    if (response.ok === true) {
      if (!response.response.errorInfo) {
        return response;
      } else {
        if (
          response.response.errorInfo.exceptionMessage &&
          response.response.errorInfo.exceptionMessage.includes("workstation_id_unique")
        ) {
          return {
            ok: false,
            error: {
              message: "Please verify your workstation ID (it has to be unique) and get in contact with convercus service",
            },
          };
        }
        return { ok: false, error: { message: response.response.errorInfo.errorCode } };
      }
    } else {
      return response;
    }
  }

  public async register(params: IRegister): Promise<IResponse<any>> {
    const simpleResponse = await this.p4m.register({
      organization: this.configService.getOrganization(),
      channelCode: params.simple.channelCode,
      emailAddress: params.simple.emailAddress,
      keyCode: params.simple.keyCode,
      language: params.simple.language,
      clientOptins: params.simple.clientOptins,
      clientId: params.simple.clientId,
    });
    if (simpleResponse.ok === true) {
      if (params.type !== "SIM") {
        return this.parseResponse(
          await this.p4m.updateProfileExtendedRegistration({
            organization: this.configService.getOrganization(),
            birthdate: params.extended.birthdate,
            city: params.extended.city,
            countryCode: params.extended.countryCode,
            deviceKey: params.extended.deviceKey,
            emailAddress: params.extended.emailAddress,
            familyName: params.extended.familyName,
            genderCode: params.extended.genderCode || "NA",
            givenName: params.extended.givenName,
            phoneNumber: params.extended.phoneNumber,
            streetHouseNo: params.extended.streetHouseNo,
            userReferenceCode: params.extended.userReferenceCode,
            zipCode: params.extended.zipCode,
          }),
        );
      }
      return this.parseResponse(simpleResponse);
    }
    return simpleResponse;
  }

  public setApiKey(apiKey: string): void {
    localStorage.setItem(LOCAL_STORAGE.APIKEY, this.utils.encrypt(apiKey));
  }

  private setMountingApiKey(mountingApiKey: string): void {
    localStorage.setItem(LOCAL_STORAGE.MOUNTING_APIKEY, this.utils.encrypt(mountingApiKey));
  }

  private getStore(): string {
    return this.utils.decrypt(localStorage.getItem(LOCAL_STORAGE.STORE));
  }

  private setStore(store: IStore): void {
    localStorage.setItem(LOCAL_STORAGE.STORE, this.utils.encrypt(JSON.stringify(store)));
  }

  private parseDisplayAds(displayAds: IDownloadDisplayAdsResponse): IDisplayAd[] {
    const resp = [];
    if (displayAds && displayAds.displayAds) {
      displayAds.displayAds.forEach(d => {
        resp.push(d);
      });
    }
    return resp;
  }

  private async AdminMultiLogin(
    email: string,
    organization: string,
    password: string,
  ): Promise<IResponse<{ mountingApiKey: string; token: string }>> {
    let token: string, mountingApiKey: string;
    const loginResp = await this.p4m.loginToGetApiKey({ userName: email, organization, password });
    if (loginResp.ok === true) {
      const resp = this.parseResponse(loginResp);
      if (resp.ok === false) {
        return resp;
      }
    } else {
      return loginResp;
    }
    const response = await Promise.all([
      this.p4m.login({ email, organization, password }),
      this.p4m.deviceManagementLogin({ userName: email, organization, password }),
    ]);
    const isValidLogin = response.filter(({ ok }) => !ok);
    if (isValidLogin.length === 0) {
      if (response[0].ok === true) {
        token = response[0].response;
      } else {
        return response[0];
      }
      if (response[1].ok === true) {
        const resp = this.parseDeviceManagementLogin(response[1].response);
        if (resp.ok === true) {
          mountingApiKey = resp.response;
        } else {
          return resp;
        }
      } else {
        return response[1];
      }
      return {
        ok: true,
        response: {
          mountingApiKey,
          token,
        },
      };
    } else {
      return isValidLogin[0] as IResponse<any>;
    }
  }

  private parseDeviceManagementLogin(response: IDeviceManagementAuthenticationResponse): IResponse<string> {
    if (!response.errorInfo) {
      return { ok: true, response: response.authenticationKey };
    } else {
      return { ok: false, error: { message: response.errorInfo.errorCode } };
    }
  }

  private async getApiKeyProcess(organization: string, clientId: string): Promise<IResponse<string>> {
    const userPermissionResponse = await this.p4m.getPermissionUser({ clientId, organization });
    if (userPermissionResponse.ok === true) {
      return this.parseGetPermissionUser(userPermissionResponse.response);
    } else {
      return userPermissionResponse;
    }
  }
}
