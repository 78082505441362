import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "orderBy",
})
export class OrderByPipe implements PipeTransform {
  public transform(array: Array<any>, args: string): Array<any> {
    const argsArray = args.split(" ");
    switch (argsArray.length) {
      case 0:
        return (array || []).sort((a, b) => {
          if (a > b) return -1;
          if (a < b) return 1;
          return 0;
        });

      case 1:
        return (array || []).sort((a, b) => {
          if (a[argsArray[0]] > b[argsArray[0]]) return -1;
          if (a[argsArray[0]] < b[argsArray[0]]) return 1;
          return 0;
        });

      case 2:
        return (array || []).sort((a, b) => {
          if (a[argsArray[0]][argsArray[1]] > b[argsArray[0]][argsArray[1]]) return -1;
          if (a[argsArray[0]][argsArray[1]] < b[argsArray[0]][argsArray[1]]) return 1;
          return 0;
        });
      default:
        return array;
    }
  }
}
